.section {

	&__title {
		@include black;
		text-align: center;
		font-size: 48px;
		margin-bottom: 63px;
		letter-spacing: 0.6px;

		.green {
			color: $themeColor; }

		@include tablet {
			margin-bottom: 37px;
			font-size: 32px; }

		@include md {
			margin-bottom: 32px;
			font-size: 24px; }

		&_big {
			font-size: 48px;
			font-weight: 700;
			text-transform: uppercase; } }

	&__subtitle {
		font-weight: 400;
		text-align: center;
		font-size: 18px;
		line-height: 1.6;
		max-width: 630px;
		margin: 30px auto 40px;

		@include tablet {
			font-size: 16px;
			margin: 30px auto 30px; } }

	&__text {
		max-width: 950px;
		margin: 0 auto;
		@include tablet {
			max-width: 870px; } } }
