.location {
	padding: 82px 0 100px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	@include sm {
		//padding: 40px 0 206px
		padding: 40px 0 0; }
	&>.container {
		position: relative;
		z-index: 2;
		@include xs {
			padding: 0; } }
	&__logo {
		max-width: 478px;
		margin: 0 auto 78px;
		@include r(320) {
			@include rh(568) {
				margin-bottom: 164px; } }
		img {
			width: 100%; } }
	&__map {
		padding: 306px 45px 56px;
		display: flex;
		justify-content: flex-end;
		//opacity: .9
		position: relative;
		@include tablet {
			max-width: 940px;
			margin: 0 auto;
			padding: 206px 45px 56px; }
		@include md {
			max-width: 680px;
			padding: 106px 25px 26px; }
		@include sm {
			opacity: 1;
			padding: 0;
			flex-direction: column;
			//padding-top: 65.9%
			position: relative; } }
	&__title {
		letter-spacing: 0.5px;
		font-size: 26px;
		text-transform: uppercase;
		@include black;
		margin-bottom: 17px;
		@include sm {
			margin-bottom: 15px;
			@include bold;
			font-size: 22px; } }
	&__address {
		letter-spacing: 0.4px;
		font-size: 20px;
		line-height: 1.5;
		margin-bottom: 41px;
		@include sm {
			font-size: 16px;
			margin-bottom: 31px; } }
	&__info {
		position: relative;
		z-index: 1;
		@include sm {
			padding: 35px 20px;
			//position: absolute
			//bottom: 0
			//left: 0
			width: 100%;
			//transform: translateY(100%)
			background-color: #080908; } }
	&__top {
		position: relative;
		padding: 0 50px; }
	&__slider {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; }
	&__slide {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover; }
	&__arrow {
		font-size: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: translate(0, -50%);
		@include xs {
			transform: translate(0, 0);
			top: 45%;
			font-size: 50px; }
		&_prev {
			left: 0; }
		&_next {
			right: 0; }
		svg {
			fill: $white; } }
	&__img {
		position: absolute;
		width: 160px;
		top: 24px;
		right: 24px;
		z-index: 1;
		@include md {
			display: none; }
		img {
			width: 100%; } } }

.map {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include sm {
		position: relative;
		height: auto;
		padding-top: 65.9%; }
	.gm-style .gmnoscreen, .gmnoscreen {
		display: none; } }


