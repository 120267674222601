.speakers {
  padding: 110px 0 17px;
  @include sm {
    padding-top: 53px; }
  &_pt-sm {
    padding-top: 94px;
    @include laptop {
      padding: 80px 0 10px; } }
  &_center {
    .speakers-list {
      justify-content: center;
      @include xs {
        justify-content: flex-start;
        //flex-direction: column
 } } }        //align-items: center
  &.no-tags {
    .speaker__tag {
      display: none; } }

  @include laptop {
    padding: 80px 0 10px; }
  //&_mb-sm
  //.speakers-list
  //  &__item
  //    &:last-child
  //      @include xs
  //        margin-bottom: 30px
  &__btn {
    max-width: 283px;
    margin: 0 auto; } }

.speakers-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -19px;
  justify-content: center;

  &__item {
    width: 20%;
    padding: 0;
    margin-bottom: 80px;
    @include r(1440);
    width: 25%;
    @include tablet {
      width: 25%;
      margin-bottom: 48px; }
    @include md {
      width: 33.33%;
 }      //margin-bottom: 30px
    @include sm {
      width: 50%;
 }      //margin-bottom: 30px
    @include xs {
      padding: 0;
      //width: 100%
      //max-width: 250px
 }      //margin: 0 auto 48px

    &_cfp {
      padding: 20px;
      @include xs {
        padding: 10px 10px; } } } }
.light {

  .speaker {

    &__desc {
      color: $text2;

      @include sm {
        color: $text2; } }

    &__social-link {
      .icon {
        fill: $text2; }
      &:hover {
        .icon {
          fill: $themeColor; } } } } }

.alt-styles {

  .speaker {

    &__name,
    &__desc,
    &__company {
      color: $white;

      @include sm {
        color: $white; } }

    &__social-link {
      .icon {
        fill: $white; }
      &:hover {
        .icon {
          fill: $white; } } } } }

.speaker {
  position: relative;
  padding: 19px;
  color: $text2;

  &:hover {
    .activity-holder {
      opacity: 1;
      visibility: visible; } }

  &:active {
    .activity-holder {
      opacity: 1;
      visibility: visible; } }

  &:hover {
    &:after {
      opacity: 0.04; } }

  @include xs {
    padding: 10px; }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: #fff;
    z-index: -1;
    opacity: 0;
    transition: all ease 0.2s; }

  &__tag {
    position: absolute;
    right: 14px;
    top: 14px;
    background: var(--tagBG);
    padding: 2px 10px;
    font-size: 14px;
    line-height: 1.8;
    border-radius: 5px;

    @include xs {
      right: 5px;
      top: 5px;
      font-size: 12px;
      padding: 2px 10px; } }

  &__expertise {
    position: absolute;
    right: 10px;
    bottom: 0;
    background: var(--tagBG);
    padding: 4px 5px;
    font-size: 14px;
    line-height: 1.8;

    @include xs {
      right: 7px;
      font-size: 12px;
      padding: 2px 5px; }

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 23px;
      background: red;
      background-color: var(--tagBG);

      @include xs {
        height: 18px;
        width: 7px; } }

    &:before {
      left: -10px;
      top: auto;
      bottom: 0;

      @include xs {
        left: -7px; } }

    &:after {
      top: 0;
      right: -10px;

      @include xs {
        right: -7px; } } }

  &__photo {
    width: 100%;
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;

    @include xs {
      margin-bottom: 23px; }
    img {
      position: relative;
      left: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
      filter: grayscale(100%) contrast(110%); } }

  &__popup-btn-wrap {
    width: 100%;
    padding: 100% 0 0;
    position: relative; }

  &__popup-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    left: 50%;
    bottom: 0;
    font-weight: 500;
    transform: translate(-50%, 50%);
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: 3px solid $themeColor2;
    color: $themeColor2;
    background: rgba(0, 0, 0, 0.5);
    transition: all ease 0.2s;
    box-shadow: 0 0 0 0 #fff; }

  &__expertise {
    margin: 12px 0 12px;
    position: relative;
    line-height: 1.62; }

  &__name {
    font-size: 22px;
    @include bold;
    margin-bottom: 11px;
    letter-spacing: 0.65px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include xs {
      font-size: 20px; } }

  &__talk {
    margin: 12px 0 12px;
    line-height: 1.36;

    span {
      font-weight: 600; } }

  &__company {
    @include medium;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
    @include xs {
      font-size: 14px;
      margin-bottom: 20px; } }
  &__desc {
    font-size: 12px;
    line-height: 1.68;
    color: $text2;

    p {
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      &:not(:first-child) {
        display: none; } }

    @include sm {
      font-size: 13px; }
    @include xs {
      font-size: 12px;
      margin-bottom: 21px; }
    a {
      color: #bdbdbd;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } }
    &:not(:last-child) {
      margin-bottom: 14px;
      @include xs {
        margin-bottom: 14px; } } }
  &__socials {
    z-index: 2;
    position: relative;
    display: flex; }
  &__social-link {
    &:not(:last-child) {
      margin-right: 16px; }
    .icon {
      transition: 0.3s;
      font-size: 18px;
      fill: $text2; }
    &:hover {
      .icon {
        fill: $text; } } }
  &--mc {
    .speaker {
      &__photo {
        width: 179px;
        padding-top: 179px;
        margin: 0 auto 59px;
        &:after {
          pointer-events: none;
          content: '';
          position: absolute;
          top: 49%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 235px;
          height: 235px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../img/mc-decor.png); } }
      &__photo-wrap {
        border-radius: 50%;
        @include coverdiv;
        overflow: hidden; } } }

  .activity-holder {
    position: absolute;
    transition: top 150ms ease;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.2s;
    color: #fff;
    cursor: pointer;
    z-index: 2;

    @include md {
      padding: 15px; }

    a {
      color: $white;
      opacity: 0.7;
      margin: 18px 0 0;
      display: inline-block;
      text-decoration: none;

      &:hover {
        opacity: 1; } }

    .slider {
      position: relative;
      width: auto;
      z-index: 1;
      .s-item__col {
        text-align: left;
        width: unset;
        padding: 0;
        padding-left: 0;
        padding-right: 0;
        @include md {
          font-size: 12px;
          padding-top: 0; } }
      .s-item__text {
        font-size: 12px;
        line-height: 1.3;
        color: #fff; }

      .slider__title {
        font-size: 16px;
        margin-bottom: 20px;
        min-height: 28px;
        @include md {
          font-size: 12px;
          margin-bottom: 4px;
          min-height: unset; } }
      .s-item__title {
        color: #fff;
        @include md {
          font-size: 14px; } }

      .icon-lightning {
        margin: 0px 0 -3px;
        fill: #888;
        width: 15px;
        height: 15px; } } } }

.hosts {
  position: relative;
  padding: 80px 0 80px;
  background-position: center top;
  background-repeat: repeat;
  // background-image: url(../img/bg-net.svg)
  // border-bottom: 2px solid #1d201e

  // &:after
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  height: 15px;
  background-image: url(../img/bg-dash.svg);
  z-index: 1;

  @include xs {
    padding: 40px 0 40px;
 } }    //margin-top: 20px

.mc {
  position: relative;
  padding: 102px 0 122px;
  background-position: center top;
  background-repeat: repeat;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 15px;
    background-image: url(../img/bg-dash.svg);
    z-index: 1; }

  @include xs {
    padding: 37px 0 55px;
 } }    //margin-top: 20px

.mc-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -19px;
  @include xs {
    padding-top: 20px; }
  &__item {
    position: relative;

    @include xs {
      margin: 0 auto; }
    &:not(:last-child) {
      margin-right: 45px;
      @include sm {
        margin-right: auto;
        margin-bottom: 79px; } } }

  .speaker {

    &__desc {

      p {
        overflow: visible;
        text-overflow: ellipsis;
        display: block;
        -webkit-line-clamp: none;
        -webkit-box-orient: vertical;

        &:not(:first-child) {
          display: none; } } } } }
