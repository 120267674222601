.program {
	padding: 143px 0 100px;
	@include md {
		padding: 40px 0 60px; }
	&__inner {
		max-width: 1122px;
		margin: 0 auto;
		&:not(:last-child) {
			margin-bottom: 63px; } }
	&__subtitle {
		font-size: 33px;
		letter-spacing: 0.33px;
		text-align: center;
		margin-bottom: 53px;
		@include md {
			font-size: 22px;
			margin-bottom: 20px; }
		&_mb-lg {
			margin-bottom: 83px;
			@include md {
				margin-bottom: 30px; } } }
	&__title {
		font-size: 80px;
		display: block;
		margin-bottom: 76px;
		@include laptop {
			font-size: 5.6vw; }
		@include sm {
			margin-bottom: 40px; }
		@include xs {
			font-size: 34px; } }
	&__item {
		color: $white;
		&:not(:last-child) {
			margin-bottom: 63px;
			@include md {
				margin-bottom: 30px; } }
		&_mb-lg {
			&:not(:last-child) {
				margin-bottom: 175px;
				@include md {
					margin-bottom: 30px; } } }
		&_mb-xl {
			&:not(:last-child) {
				margin-bottom: 185px;
				@include sm {
					margin-bottom: 80px; } } }
		p {
			font-size: 22px;
			line-height: 1.7;
			letter-spacing: 0.55px;
			text-align: center;
			@include md {
				font-size: 16px; }
			a {
				color: inherit; }
			&:not(:last-child) {
				margin-bottom: 50px; } }
		img {
			width: 825px;
			max-width: 100%;
			display: block;
			margin: 0 auto; } }
	&__img-wrap {
		max-width: 1040px;
		margin: 0 auto;
		line-height: 0;
		display: flex;
		@include sm {
			flex-direction: column; }
		&:not(:last-child) {
			margin-bottom: 92px; }
		.program__img {
			padding-top: 24.5%;
			max-width: 50%;
			width: 100%;
			@include sm {
				max-width: 100%; } } }

	&__img {
		width: 825px;
		max-width: 100%;
		display: block;
		margin: 0 auto;
		padding-top: 29%;
		background-position: center;
		background-size: 100% auto;
		transition: background-size 1s ease;
		&:hover {
			background-size: 120% auto; } } }



.program-map {
	&:not(:last-child) {
		margin-bottom: 184px;
		@include md {
			margin-bottom: 90px; }
		@include sm {
			margin-bottom: 60px; } }
	&__icon {
		display: flex;
		justify-content: center;
		margin-bottom: 90px;
		position: relative;
		line-height: 0;
		&:after {
			position: absolute;
			content: '';
			display: block;
			width: 4px;
			height: 70px;
			top: calc(100% + 10px);
			left: 50%;
			transform: translate(-50%, 0);
			background-image: linear-gradient(#2C302D 50%, transparent 50%);
			background-size: 100% 20px; } }
	.program-map-item {
		&:not(:last-child) {
			margin-bottom: 69px; }
		&:not(:first-child) {
			position: relative;
			&:before {
				position: absolute;
				content: '';
				display: block;
				width: 4px;
				height: 50px;
				top: -12px;
				left: 50%;
				transform: translate(-50%, -100%);
				background-image: linear-gradient(#2C302D 50%, transparent 50%);
				background-size: 100% 20px; } } } }
.program-map-item {
	display: flex;
	border: 1px solid #2c302d;
	position: relative;
	@include sm {
		flex-direction: column; }
	&__content {
		color: #888;
		font-size: 22px;
		line-height: 1.36;
		letter-spacing: 0.29px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: calc((100% - 374px) / 2);
		padding: 5px;
		@include md {
			font-size: 18px; }
		@include sm {
			width: 100%;
			padding: 20px 10px;
			color: #aaaaaa; }
		a {
			color: inherit;
			text-decoration: none;
			transition: color .3s ease;
			&:hover {
				text-decoration: underline;
				color: #ffffff; } } }
	&__title {
		color: $white;
		&:not(:last-child) {
			margin-bottom: 28px;
			@include sm {
				margin-bottom: 15px; } } }
	&__time {
		font-size: 26px;
		letter-spacing: 0.34px; }
	&__img {
		width: 374px;
		border-left: 1px solid #2c302d;
		border-right: 1px solid #2c302d;
		line-height: 0;
		@include sm {
			border-left: none;
			border-right: none;
			border-top: 1px solid #2c302d;
			border-bottom: 1px solid #2c302d;
			width: 100%; }
		img {
			width: 100%; } }
	&__link {
		color: #888;
		font-size: 18px;
		text-decoration: none;
		@include sm {
			color: #aaaaaa; }
		&:hover {
			color: $white;
			.icon {
				fill: $white; } }

		.icon {
			margin-right: 5px;
			fill: #aaaaaa;
			vertical-align: text-bottom; }
		span {
			text-decoration: underline; } } }

