.hero {
	position: relative;
	min-height: 100vh;
	//min-height: calc(var(--vh, 1vh) * 100)
	padding: 30px 0 80px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	//background-image: url(../img/hero-overlay.png)
	background-color: #1e2220;
	z-index: 1;
	pointer-events: none;

	&.hero_inner {
		min-height: 70vh; }

	// &:after
	//ontent: ''
	//include coverdiv
	//-index: 1
	//ackground-color: #000
	//pacity: 0.35
	// &:before
	// 	content: ''
	// 	+coverdiv
	// 	z-index: 1
	// 	background-image: url(../img/hero-overlay.png)
	// 	background-position: center
	// 	background-repeat: no-repeat
	// 	background-size: cover
	@include tablet {
		padding: 45px 0 105px; }
	@include md {
		padding: 60px 0 80px; }
	@include sm {
		padding: 20px 0 60px; }
	@include r(380) {
		@include rhmin(600) {
			padding: 20px 0 40px!important;
			.hero__logo, .h-list {
				margin-bottom: 25px; } } }
	&__video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: -1;
		@include r(899) {
			display: none; } }

	&__bg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: -1;
		// background: url(../img/screens-done-op.svg) center center no-repeat
		background-size: cover; }

	&__inner {
		width: 1566px;
		//margin: 0 auto
		padding: 0 40px;
		position: relative;
		z-index: 2;
		pointer-events: none;
		@include r(1460) {
			padding: 0 80px; }
		@include r(1279) {
			padding: 0 40px; }
		@include sm {
			padding: 0 20px; }
		@include xs {
			padding: 0 15px; } }

	&__logo {
		width: 166px;
		//height: 101px
		margin: 0 auto 66px;
		@include tablet {
			margin: 0 auto 30px;
			width: 154px; }
		//height: 91px
		@include md {
			width: 137px;
			//height: 82px
			margin: 0 auto 30px; }
		@include xs {
			margin: 0 auto 26px;
			width: 140px;
			height: auto; }

		img {
			display: block; } }

	&__suptitle {
		font-size: 18px;
		margin-bottom: 66px;
		text-align: center;
		@include tablet {
			font-size: 18px;
			margin-bottom: 30px; }
		@include md {
			margin-bottom: 28px; }
		@include xs {
			font-size: 15px;
			margin: 0 -10px 26px; } }
	&__title {
		font-size: 55px;
		line-height: 1.1;
		text-align: center;
		margin-bottom: 60px;
		@include black;
		@include laptop {
			font-size: 52px; }

		@include tablet {
			font-size: 48px;
			line-height: 1.11;
			margin-bottom: 30px;
			margin-left: -30px;
			margin-right: -30px; }
		@include md {
			font-size: 7.2vw;
			line-height: 1;
			margin-bottom: 32px; }
		@include sm {
			margin-bottom: 20px;
			margin-left: 0;
			margin-right: 0; }
		@include xs {
			font-size: 32px;
			margin: 0 auto 26px;
			line-height: 1.16;
			max-width: calc(100% - 5px); } }
	&__btn {
		//max-width: 283px
		//margin: 0 auto
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.btn {
			pointer-events: auto;
			&:not(:first-child) {
				margin: 0 0 0 20px; } }

		@include sm {
			flex-direction: column;

			.btn {
				margin: 10px auto 0;

				&:not(:first-child) {
					margin: 10px auto 0; } } } }

	&__partner {
		position: absolute;
		right: 10px;
		top: 0;
		font-size: 11px;
		line-height: 1.4;
		pointer-events: auto;

		@include tablet {
			font-size: 14px;
			right: 20px; }

		@include md {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			right: 0;
			margin: 20px auto; } }

	&__partners {
		display: flex;
		margin-top: 4px;

		@include tablet {
			margin-top: 5px; } }

	&__partner-img {
		display: flex;
		align-items: center;
		justify-content: center;

		&:not(:first-child) {
			margin: 0 0 0 20px;

			@include sm {
				margin: 0 0 0 10px; } }

		img {
			height: 60px;
			width: auto; } }

	&__timer {
		position: absolute;
		padding: 0 4vw;
		right: 0;
		left: 0;
		bottom: 1vw;
		font-size: 15vw;
		font-weight: 600;
		color: $themeColor2;
		opacity: .3;
		line-height: 1;
		white-space: nowrap;

		.live {
			text-align: center; } }

	&_live {
		align-items: flex-start;
		height: 150px;
		min-height: 0;
		padding: 20px 0;

		@include tablet {
			padding: 20px 0; }
		@include md {
			padding: 20px 0;
			height: 120px; }
		@include sm {
			padding: 10px 0; }
		@include xs {
			height: auto;
			padding: 10px 0 !important; }


		.container {
			position: relative;
			z-index: 2;
			pointer-events: auto;
			width: 100%;
			height: 100%;
			padding: 0 80px;

			@include sm {
				padding: 0 60px; } }

		.hero {
			&__bg {
				z-index: -1;
				background: url(../img/screens-done-op.svg) center top no-repeat, $mainBg;
				background-size: cover; }

			&__inner {
				position: relative;
				width: 100%;
				height: 100%;
				padding: 0;
				margin: 0;
				display: flex;
				justify-content: space-between;

				@include md {
					margin: 0; }

				@include xs {
					flex-direction: column-reverse;
					align-items: center; } }

			&__date {
				font-size: 18px;
				margin: auto 0;

				@include md {
					font-size: 14px; }

				@include xs {
					font-size: 12px;
					margin: 15px 0 10px; } }

			&__logo {
				width: 100px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				margin: 0;

				@include md {
					width: 100px;
					margin: 0px auto 0; }

				@include xs {
					position: relative;
					left: 0;
					top: 0;
					transform: translate(0, 0);
					width: 100px;
					margin: 0px auto 0; } }

			&__partner {
				position: relative;
				height: auto;
				margin: auto 0 auto auto;
				top: 0;
				right: 0;

				@include tablet {
					font-size: 10px; }

				@include md {
					margin: auto 0 auto;

					img {
						height: 30px; } }

				@include xs {
					display: none; }

				img {
					height: 50px; } }

			&__partners {
				@include md {
					margin: 0; } }



			&__partner-text {
				@include sm {
					display: none; } } } } }


.h-list {
	display: flex;
	justify-content: space-between;
	max-width: 1100px;
	margin: 0 auto 152px;
	padding: 0 50px;

	&_centered {
		justify-content: center; }

	&_min {
		max-width: 411px; }
	@include tablet {
		margin: 0 auto 52px; }
	@include md {
		max-width: 600px;
		margin-bottom: 65px;
		padding: 0; }
	@include xs {
		flex-wrap: wrap;
		justify-content: center;
		padding: 0;
		margin-bottom: 45px; }
	//max-width: 230px
	&__item {
		display: flex;

		@include md {
			flex-direction: column;
			text-align: center; }

		@include sm {
			width: 50%;
			align-items: center;
			text-align: center; }
		&:not(:last-child) {
			margin-right: 40px;
			@include sm {
				margin-right: 0; } }
		@include xs {
			margin-bottom: 9px; }
		&:nth-child(2n) {
			@include xs {
				margin-right: 0; } } }
	//+xs
 //     margin-right: 0
 //     //margin-bottom: 20px
 //     margin-bottom: 15px
	//&_sm
 //     @include xs
 //         max-width: 90px
	//&_lg
 //     @include xs
 //         width: auto
 //         max-width: unset

	&__value {
		font-size: 52px;
		margin: 0 20px 0 0;
		@include bold;
		display: flex;
		align-items: center;
		@include md {
			margin: 0;
			justify-content: center; }
		@include sm {
			justify-content: flex-start; }
		@include xs {
			margin-bottom: 0;
			font-size: 48px;
			line-height: 1.1; }

		.icon-infinity {
			width: 71px;
			height: 60px;
			fill: #FFF; }

		span {
			position: relative;
			font-size: 62px;
			line-height: 1;
			top: -4px;

			@include xs {
				font-size: 54px;
				top: -2px; } } }

	&__text {
		font-size: 14px;
		line-height: 1.4;
		padding: 2px 0;
		&-top {
			font-size: 24px;
			@include bold;
			@include tablet {
				font-size: 22px; }
			@include xs {
				font-size: 18px; } }
		@include xs {
			font-size: 12px; }
		@include tablet {
			padding: 5px 0; }
		@include md {
			padding: 0; } }
	&__icon {
		margin-top: 10px;
		margin-left: 8px;
		font-size: 30px;
		&_anim {
			animation: heart 2s infinite; }
		.icon {
			fill: $white; }
		@include sm {
			margin-top: 6px;
			.icon {
				width: 0.9em; } } } }



@keyframes heart {
	0% {
		transform: scale(1); }
	50% {
		transform: scale(1.2); }
	100% {
		transform: scale(1); } }


.logo {
	display: block;
	//height: 100%
	width: 100%;
	pointer-events: auto;
	img {
		//height: 100%
		width: 100%; } }
