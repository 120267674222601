.extended {
	padding: 148px 0 150px;
	@include laptop {
		padding: 70px 0; }
	@include md {
		padding: 40px 0; }
	@include sm {
		padding: 13px 0;
		margin-bottom: 34px; }
	.container {
		@include sm {
			padding: 0; } }
	&__items {
		margin: 0 auto;
		max-width: 1200px;
		display: flex;
		margin-bottom: 70px;
		@include tablet {
			margin-left: -40px;
			margin-right: -40px; }

		@include sm {
			margin-left: 0;
			margin-right: 0;
			display: block;
			margin-bottom: 12px; }
		@include xs; }

	&__item {
		width: 33.33%;
		@include sm {
			margin: 0 auto;
			width: 100%; }
		@include xs;

		.party-item {
			height: 100%; } }
	&__link {
		text-align: center; }
	&__btn {
		max-width: 260px;
		margin: 0 auto;
		@include sm {
			max-width: 280px; } } }

.party-item {
	overflow: hidden;

	&:hover {
		.party-item__img {
			transform: scale(1.1); } }

	&__img {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		transform: scale(1);
		z-index: -2;
		background-position: center center;
		background-size: cover;
		transition: all ease 0.2s; }

	&__header {
		z-index: 1;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		padding-top: 128%;
		width: 100%;
		&:after {
			z-index: -1;
			content: '';
			@include coverdiv;
			background-color: rgba(0, 0, 0, 0.5); }
		@include sm {
			padding-top: 50%; }
		@include xs {
			padding-top: 100%; } }

	&__header-inner {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 36px 28px 38px;
		line-height: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include sm {
			padding: 20px 20px 30px; } }
	&__title {
		@include bold;
		text-transform: uppercase;
		font-size: 44px;
		margin-bottom: auto;
		@include tablet {
			font-size: 3.4vw; }
		@include md {
			margin-bottom: 10px; }
		@include sm {
			font-size: 46px; }
		@include xs {
			font-size: 8.6vw; } }
	&__subtitle {
		font-size: 18px;
		margin: 0 0 14px;
		@include md {
			font-size: 16px; } }
	&__content {
		color: #FFF;
		font-size: 16px;
		line-height: 1.8;
		a {
			color: inherit;
			white-space: nowrap;
			@include medium; } }
	&__desc {
		font-size: 14px;
		min-height: 66px;
		line-height: 1.58;
		@include sm {
			min-height: 0; } } }

