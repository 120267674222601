.icon-anchor {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-arrow-l {
    width: 0.36em;
    height: 1em;
    fill: #00DD3B;
  }
  .icon-arrow-r {
    width: 0.36em;
    height: 1em;
    fill: #00DD3B;
  }
  .icon-arrow-r2 {
    width: 0.59em;
    height: 1em;
    fill: initial;
  }
  .icon-arrow-right {
    width: 1em;
    height: 1em;
    fill: #121313;
  }
  .icon-cam {
    width: 1.19em;
    height: 1em;
    fill: initial;
  }
  .icon-chat {
    width: 1.27em;
    height: 1em;
    fill: initial;
  }
  .icon-discord {
    width: 1.46em;
    height: 1em;
    fill: initial;
  }
  .icon-equal {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-face {
    width: 0.78em;
    height: 1em;
    fill: initial;
  }
  .icon-face_1 {
    width: 0.75em;
    height: 1em;
    fill: initial;
  }
  .icon-face_10 {
    width: 0.78em;
    height: 1em;
    fill: initial;
  }
  .icon-face_11 {
    width: 0.75em;
    height: 1em;
    fill: initial;
  }
  .icon-face_12 {
    width: 0.75em;
    height: 1em;
    fill: initial;
  }
  .icon-face_2 {
    width: 0.78em;
    height: 1em;
    fill: initial;
  }
  .icon-face_3 {
    width: 0.75em;
    height: 1em;
    fill: initial;
  }
  .icon-face_4 {
    width: 1.16em;
    height: 1em;
    fill: initial;
  }
  .icon-face_5 {
    width: 0.89em;
    height: 1em;
    fill: initial;
  }
  .icon-face_6 {
    width: 0.75em;
    height: 1em;
    fill: initial;
  }
  .icon-face_7 {
    width: 0.82em;
    height: 1em;
    fill: initial;
  }
  .icon-face_8 {
    width: 0.78em;
    height: 1em;
    fill: initial;
  }
  .icon-face_9 {
    width: 0.75em;
    height: 1em;
    fill: initial;
  }
  .icon-fb {
    width: 0.44em;
    height: 1em;
    fill: initial;
  }
  .icon-gh {
    width: 1.02em;
    height: 1em;
    fill: #f59423;
  }
  .icon-heart {
    width: 1.09em;
    height: 1em;
    fill: initial;
  }
  .icon-help {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-infinity {
    width: 1.97em;
    height: 1em;
    fill: initial;
  }
  .icon-lightning {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-md {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-med {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-perks {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-plus {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-portal {
    width: 0.79em;
    height: 1em;
    fill: initial;
  }
  .icon-remo {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-schedule {
    width: 1.01em;
    height: 1em;
    fill: initial;
  }
  .icon-screens_controls {
    width: 2.36em;
    height: 1em;
    fill: initial;
  }
  .icon-screens_dots {
    width: 0.24em;
    height: 1em;
    fill: initial;
  }
  .icon-screens_star {
    width: 1.06em;
    height: 1em;
    fill: initial;
  }
  .icon-site {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-sl {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-spatial {
    width: 0.62em;
    height: 1em;
    fill: initial;
  }
  .icon-tickets {
    width: 0.82em;
    height: 1em;
    fill: initial;
  }
  .icon-tw {
    width: 1.19em;
    height: 1em;
    fill: initial;
  }
  .icon-vertical-scroll {
    width: 1em;
    height: 1em;
    fill: initial;
  }
  .icon-yb {
    width: 1.36em;
    height: 1em;
    fill: initial;
  }
  