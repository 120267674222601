@import "_variables.sass";

html {
	--color-brand: #{$themeColor2};
	--color-brand-hover: #{$white};
	--color-popup-ticket-border: #{$themeColor2}; }

.block {
	display: flex;
 }	// remove this
