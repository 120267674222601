.footer {
	&__socials {
		margin-bottom: 88px;
		@include laptop {
			margin-bottom: 40px; }
		@include sm {
			margin-bottom: 31px; }
		.social {
			&__item {
				svg {
					@include xs {
						font-size: 25px; } } } } }
	&__main {
		padding: 152px 0 138px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include laptop {
			padding: 80px 0 60px; } }

	&__title {
		margin-bottom: 70px; }

	&__basement {
		background-image: url(../img/bg-heads.png);
		background-repeat: repeat;
		//background-size: cover
		background-position: -3px center;
		animation: translateBG 50s linear infinite;
		//padding: 122px 20px 244px
		padding: 122px 20px 147px;
		display: flex;
		justify-content: center;
		@include laptop {
			padding: 80px 20px 100px; }
		@include md {
			padding: 55px 20px 63px; } } }

.f-logo {
	color: $white;
	text-decoration: none;
	display: flex;
	align-items: center;

	&__label {
		font-size: 20px;
		margin-right: 47px;
		@include xs {
			font-size: 18px;
			margin-right: 20px; } }
	&__icon {
		width: 71px;
		@include xs {
			width: 56px; }
		img {
			width: 100%; } }
	&__author {
		color: $white;
		text-decoration: none;
		font-size: 36px;
		margin-left: 26px;
		&:hover {
			text-decoration: underline; }
		@include xs {
			font-size: 20px;
			margin-left: 15px; } } }

.f-nav {
	display: flex;
	font-size: 16px;
	@include sm {
		display: block;
		text-align: center; }
	&__item {
		&:not(:last-child) {
			margin-right: 45px;
			@include md {
				margin-right: 15px; }
			@include sm {
				margin-right: 0;
				margin-bottom: 10px; } } }
	&__link {
		color: $themeColor2;
		text-decoration: none;
		transition: 0.3s;
		.no-touch &:hover {
			text-decoration: underline; } } }

@keyframes translateBG {
	0% {
		background-position: 0 0; }
	100% {
		background-position: -798px 0; } }
