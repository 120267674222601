.faq {
  padding: 0;
  margin: 0 auto 50px;
  list-style: none;
  max-width: 970px;
  font-weight: normal;
  color: $white;

  &__section-title {
    font-size: 28px;
    font-weight: 500;
    margin: 50px 0 30px; }

  &__list {
    margin: 20px auto 20px; }

  &__description {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    padding: 20px 0;
    margin: 30px 0 0;

    a {
      color: $themeColor2; } }

  &.marged {
    margin-top: 30px; }

  &__arrow {
    position: absolute;
    top: 30px;
    right: 42px;
    width: 10px;
    height: 12px;
    transform: rotate(90deg);
    transition: all 0.5s;

    .icon {
      fill: $themeColor2; }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent #007bff; } }

  &__item {
    position: relative;
    margin: 0 0 -2px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 2px;
      width: 2px;
      background: #fff;
      transition: all ease .3s;
      opacity: 0.1; }

    &.open {
      .faq__arrow {
        transform: rotate(-90deg); }

      &:after {
        background: $themeColor2;
        opacity: 1; } } }

  &__question {
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
    padding: 25px 90px 25px 40px; }

  &__ansver {
    font-size: 16px;
    line-height: 1.5;
    display: none;
    padding: 0 40px 25px;
    max-width: 840px;

    a {
      color: $themeColor2; }

    p {
      margin: 16px 0 0;
      color: $white;
      &:first-child {
        margin: 0; } } }

  @include sm {

    &__section-title {
      margin: 40px 0 20px;
      font-size: 26px;
      font-weight: 400; }

    &__list {
      margin: 20px -20px; }
    &__arrow {
      top: 24px;
      right: 28px; }

    &__question {
      font-size: 20px;
      cursor: pointer;
      padding: 15px 60px 15px 20px; }

    &__ansver {
      font-size: 14px;
      padding: 0 20px 15px; } }

  @include xs {

    &__section-title {
      font-size: 24px; }

    &__question {
      font-size: 18px; } } }
