.s-prices {
	color: #fff;
	padding: 50px 0; }

.prices {
	border: 15px solid $themeColor2;
	background: $mainBg;
	font-size: 16px;

	@include sm {
		margin: 0 -20px; }

	&__list {
		position: relative;
		padding: 30px 0 30px;
		margin: 0;
		list-style: none;
		display: flex;
		overflow-x: auto;

		&::-webkit-scrollbar {
			width: 16px;
			height: 16px; }

		&::-webkit-scrollbar-button {
			width: 0px;
			height: 0px; }

		&::-webkit-scrollbar-thumb {
			background: #333;
			border: 0px solid #fff;
			border-radius: 0px;
			cursor: pointer; }

		&::-webkit-scrollbar-thumb:hover {
			background: #333; }

		&::-webkit-scrollbar-thumb:active {
			background: #000; }

		&::-webkit-scrollbar-track {
			background: #e1e1e1;
			border: 0px solid #fff;
			border-radius: 0px; }

		&::-webkit-scrollbar-track:hover {
			background: #e1e1e1; }

		&::-webkit-scrollbar-track:active {
			background: #333; }

		&::-webkit-scrollbar-corner {
			background: transparent; }

		@include tablet;

		@include sm {
			padding: 30px 0 10px; } }

	&__glow {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 50px;
		background: linear-gradient(to right, transparent, #001a5d);
		z-index: 1;
		opacity: 1;
		transition: opacity ease 0.2s;
		pointer-events: none; }




	&__item {
		display: flex;
		flex-direction: column;
		flex: 1 1;
		text-align: center;
		padding: 22px 15px 30px;
		min-width: 300px;

		@include sm {
			min-width: 300px; }


		&:not(:first-child) {
			border-left: 1px solid rgba(255, 255, 255, 0.05); }

		// +tablet
		// 	flex: 1 1 50%

		// 	&:nth-child(n + 3)
		// 		border-top: 1px solid rgba(255, 255, 255, 0.05)
		// 		padding-top: 50px

		// +sm
		// 	flex: 1 1 100%

		// 	&:nth-child(n + 2)
		// 		border-top: 1px solid rgba(255, 255, 255, 0.05)
 }		// 		padding-top: 50px




	&__title {
		font-size: 22px;
		line-height: 1.34;
		margin: 0 0 70px;
		@include regular;
		@include sm {
			margin: 0 0 30px; } }

	&__price {
		@include medium;
		color: $themeColor2;
		margin: 0 0 24px;

		h4 {
			@include medium;
			font-size: 60px;
			line-height: 1;
			margin: 12px 0 12px; }


		strong {
			@include medium;
			font-size: 80px;
			color: $themeColor2;
			margin: 0 0 20px;
			line-height: 1;

			&:before {
				content: '€';
				display: inline-block;
				position: relative;
				font-size: 36px;
				margin: 0 5px 0 0;
				top: -32px;
				@include medium; } } }

	&__price-tip {
		font-size: 14px;
		color: $themeColor2;

		a {
			color: $themeColor2; } }

	&__desc {
		font-size: 14px;
		margin: 45px 0 74px;
		line-height: 1.32;

		p {
			&:not(:first-child) {
				margin: 18px 0 0;

				@include sm {
					margin: 14px 0 0; } } }
		@include sm {
			margin: 15px 0 40px; }

		a {
			color: $themeColor2; } }

	&__btn {
		margin: auto auto 0; }

	&__bottom {
		font-size: 17px;
		padding: 30px 20px;
		text-align: center;
		background: #0e100f; } }
