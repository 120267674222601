.container {
	max-width: 1680px;
	padding: 0 40px;
	margin: 0 auto;
	@include md {
		padding: 0 20px; }
	&--l {
		max-width: 1374px; } }

.light {
	background: $white;
	// background: url(../img/bg-net-diagonal.svg) center top, #eaeaec
	color: $text; }
