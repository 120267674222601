.s-full-ticket {
	padding: 136px 0 10px;

	@include md {
		padding: 80px 0 10px; }

	@include sm {
		padding: 40px 0 10px; } }

.full-ticket {
	display: flex;
	margin: 70px 0 50px;

	@include md {
		margin: 50px -30px 50px; }

	@include sm {
		margin: 50px -30px 30px;
		flex-wrap: wrap; }

	&__item {
		position: relative;
		display: flex;
		flex: 1 0;
		flex-direction: column;
		font-size: 16px;
		line-height: 1.28;
		padding: 20px 40px 20px;
		min-height: 250px;
		color: #fff;
		background-size: cover;
		background-position: center center;

		@include tablet {
			padding: 20px 20px 20px; }

		@include md {
			padding: 20px 20px 20px;
			min-height: 250px; }

		@include sm {
			margin: 0 auto;
			flex: 1 1 50%;
			min-height: 250px; }

		@include xxs {
			flex: 1 1 100%;
			min-height: 250px; }

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			z-index: 0; } }

	a.full-ticket__item {
		text-decoration: underline;

		&:hover {
			text-decoration: none; } }

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-position: center center;
		background-size: cover;
		filter: grayscale(100%); }

	&__title {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin: 0 0 auto;
		min-height: 60px;
		z-index: 1; }

	&__img-wrap {
		position: relative;
		width: 100%;
		height: 60px;
		margin: 10px 0;
		z-index: 1; }

	&__img {
		display: block;
		height: 100%;
		width: auto;
		margin: 0 auto; }

	&__desc {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto 0 0;
		text-align: center;
		min-height: 60px;
		z-index: 1;

		@include md {
			min-height: 80px; }

		@include sm {
			min-height: 60px; } } }
