.screens-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	min-width: 120%;
	min-height: 100vh;
	@include sm {
		min-width: 200%; }


	&__ratio {
		position: relative;
		display: block;
		height: 100%;
		min-height: 100vh;
		width: auto;
		visibility: hidden;

		img {
			display: block;
			flex: 1 1 auto;
			height: 100%;
			width: auto;
			overflow: hidden; } } }

.screens {
	position: absolute;
	left: 0;
	width: 100%;
	top: 0;
	bottom: 0;
	z-index: -1;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	background: #0032b0;

	&__item {
		position: relative;
		padding: 5px;
		display: flex;
		overflow: hidden;
		pointer-events: auto;
		width: calc(100% / 4);
		height: calc(100% / 3);

		&:hover {
			.screens__img {
				opacity: 1; }

			.screens__screens-star,
			.screens__screens-dots,
			.screens__screens-controls {
				fill: $themeColor; } }


		// &:before
		//ontent: ''
		//osition: absolute
		//eft: 5px
		//op: 5px
		//ight: 5px
		//ottom: 5px
		//pacity: 1
		//ackground-color: #f00
		//ransition: opacity ease 0.2s
		//-index: 1
		//order-radius: 10px


		// &:after
		//ontent: ''
		//osition: absolute
		//eight: 100%
		//idth: 100%
		//eft: 0
		//op: 0
		//ackground: url(../img/screens-over.svg) center center no-repeat
		//ackground-size: cover
 }		//-index: 2

	&__overlay {
		position: absolute;
		left: 5px;
		top: 5px;
		right: 5px;
		bottom: 5px;
		background: #001854;
		border-radius: 10px;
		opacity: 1;

		.icon {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			height: 69%;
			width: 50%;
			fill: #002687; } }

	&__img {
		position: absolute;
		left: 5px;
		top: 5px;
		right: 5px;
		bottom: 5px;
		width: calc(100% - 10px);
		height: calc(100% - 10px);
		display: block;
		object-fit: cover;
		opacity: 0;
		z-index: 1;
		transition: opacity ease 0.2s;
		border-radius: 10px;
		filter: grayscale(1) brightness(70%); }

	&__screens-star {
		position: absolute;
		left: 20px;
		top: 16px;
		z-index: 2;
		width: 30px;
		height: 27px;
		fill: $themeColor; }

	&__screens-dots {
		position: absolute;
		right: 20px;
		top: 15px;
		z-index: 2;
		width: 7px;
		height: 30px;
		fill: $themeColor; }

	&__screens-controls {
		position: absolute;
		left: 20px;
		bottom: 16px;
		z-index: 2;
		width: 64px;
		height: 28px;
		fill: $themeColor; } }
