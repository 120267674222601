.giving-back {
	padding: 70px 0 50px;
	@include laptop {
		padding: 70px 0 40px; }
	.container {
		max-width: 1040px; }
	&__logos {
		align-items: center;
		flex-wrap: wrap;
		display: flex;
		justify-content: center;
		margin: 0 0 25px; }

	&__logo {
		width: 250px;
		margin: 10px 15px;

		@include sm {
			width: 160px; }

		img {
			width: 100%; } }

	&__text {
		font-size: 17px;
		margin-bottom: 60px;
		line-height: 1.68;
		max-width: 880px;
		@include sm {
			margin-bottom: 50px; } }
	&__btns {
		display: flex;
		justify-content: center;
		margin-top: 90px;
		@include xs {
			display: block;
			margin-top: 40px; }
		.btn {
			@include xs {
				font-size: 12px;
				padding-top: 23.5px;
				padding-bottom: 23.5px;
				&--border {
					padding-top: 21.5px;
					padding-bottom: 21.5px; } } }

		.btn + .btn {
			margin-left: 20px;
			@include xs {
				margin-left: 0;
				margin-top: 20px; } } }

	&__faces {
		margin-top: 100px;
		opacity: 0.07;
		@include md {
			margin-top: 50px; }
		@include xs {
			margin-top: 30px;
			margin: 35px -250px -20px; }
		img {
			width: 100%; } }

	&__sponsor {
		display: block;
		margin: 72px auto 50px;
		width: 294px;
		max-width: 100%; } }

.status-bar {
	max-width: 800px;
	margin: 0 auto;

	&__title {
		text-align: center;
		font-size: 18px;
		color: #5f5f5f;
		margin-bottom: 78px;
		@include xs {
			font-size: 16px;
			margin-bottom: 31px;
			padding: 0 35px; } }
	&__line {
		width: 100%;
		position: relative;
		margin: 50px 0;
		height: 5px;
		background: $gray; }

	&__progress {
		position: relative;
		height: 5px;
		background: $themeColor2;
		z-index: 1; } }
