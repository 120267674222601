.guarantee {
	width: 100%;
	color: $white;
	padding: 68px 0 123px;
	@include sm {
		padding: 40px 0 60px; }
	&__inner {
		max-width: 916px;
		margin: 0 auto; }
	&__title {
		font-size: 26px;
		margin-bottom: 40px;
		text-align: center; }
	&__list {
		display: flex;
		justify-content: center;
		font-size: 22px;
		&:not(:last-child) {
			margin-bottom: 90px;
			@include md {
				margin-bottom: 45px; } }
		ul {
			li {
				padding-left: 20px;
				position: relative;
				&:not(:last-child) {
					margin-bottom: 12px; }
				&:before {
					position: absolute;
					content: '';
					display: block;
					width: 7px;
					height: 7px;
					background-color: #888;
					border-radius: 50%;
					top: 11px;
					left: 1px;
					@include sm {
						background-color: #aaaaaa; } } } } }
	&__content {
		display: flex;
		justify-content: space-between;
		@include sm {
			flex-direction: column;
			align-items: center; } }
	&__item {
		max-width: 378px;
		@include sm {
			text-align: center; }
		&:not(:last-child) {
			margin-right: 20px;
			@include sm {
				margin-right: 0;
				margin-bottom: 20px; } }
		p {
			color: #888;
			font-size: 15px;
			line-height: 2;
			@include sm {
				color: #aaaaaa; } } }
	&__subtitle {
		font-size: 24px;
		&:not(:last-child) {
			margin-bottom: 29px; } } }
