.header {
	z-index: 9;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	@include md {
		bottom: auto;
		top: 0;
		padding: 20px 20px;
		display: flex;
		justify-content: flex-end;
		background-color: transparent; }
	@include md {
		pointer-events: none; }

	&__nav {
		@include md {
			width: 100%;
			margin-bottom: 10px; } }
	&__btn {
		position: absolute;
		top: 0;
		right: 0;
		width: 214px;
		.btn {
			width: 100%;
			height: 50px;
			border-radius: 0;
			padding: 0; }

		@include tablet {
			width: 160px; }
		@include md {
			width: auto;
			position: relative;
			margin: 0 0 20px;
			display: none; } }

	&__alt-btn {
		display: none;
		position: fixed;
		bottom: 0;
		right: 0;
		width: 214px;
		z-index: 2;
		pointer-events: auto;

		.btn {
			width: 100%;
			height: 50px;
			border-radius: 0;
			padding: 0; }

		@include md {
			width: auto;
			margin: 0;
			display: block;
			width: 160px; } }

	&__socials {
		position: absolute;
		display: flex;
		align-items: center;
		left: 20px;
		height: 50px;
		top: 0;
		@include md {
			order: 3;
			margin-top: 0;
			position: relative;
			left: 0;
			top: 0; } }
	&__inner {
		position: relative;
		display: flex;
		max-width: 1480px;
		padding: 0 20px;
		align-items: center;
		justify-content: center;
		min-height: 50px;
		margin: 0 auto;
		@include laptop {
			padding-left: 40px; }
		@include tablet {
			padding-left: 20px;
			min-height: 46px; }
		@include md {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			top: 0;
			left: 0;
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
			padding: 30px 0;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			background-color: rgba(0,0,0,0.5);
			//transition: opacity 0.3s
			z-index: -10;
			&.is-open {
				opacity: 1;
				visibility: visible;
				z-index: 1;
				padding-left: 0; } }

		&.is-open {
			@include md {
				pointer-events: auto; } } }

	&__burger {
		display: none;
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 2;
		@include md {
			display: inline-block;
			pointer-events: auto; } } }

.nav {
	display: flex;
	@include md {
		display: block; }
	&__item {}

	&__link {
		text-decoration: none;
		text-transform: uppercase;
		color: $white;
		transition: 0.3s;
		font-size: 12px;
		height: 50px;
		display: flex;
		padding: 0 13px;
		align-items: center;
		justify-content: center;

		&:hover {
			color: #9dde00; }

		@include md {
			font-size: 14px;
			display: flex;
			text-align: center;
			padding: 10px 20px;
			font-weight: 500; }
		@include sm {
			font-size: 12px; }
		.no-touch &:hover {
			color: $themeColor2; } } }

.burger {
	width: 60px;
	height: 60px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include sm {
		width: 50px;
		height: 50px; }

	span {
		transition: 0.3s;
		display: block;
		height: 4px;
		width: 36px;
		background-color: #ffffff;
		border-radius: 5px;
		&:not(:last-child) {
			margin-bottom: 6px; } }
	&.is-active {
		span {
			&:nth-child(1) {
				transform: translate(0, 10px) rotate(45deg); }
			&:nth-child(2) {
				transform: translateX(0);
				opacity: 0; }
			&:nth-child(3) {
				transform: translate(0, -10px) rotate(-45deg); } } } }


