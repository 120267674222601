.event {
	position: relative;
	padding: 128px 0 128px;
	background: $themeColor2;
	background: linear-gradient(to right, $themeColor, $themeColor);
	@include laptop {
		padding: 128px 0 128px; }
	@include tablet {
		padding: 72px 0; }
	@include md {
		padding: 60px 0; }
	@include sm {
		padding: 50px 0; }

	&__video {
		display: none; }

	&__heading {
		@include r(1440) {
			font-size: 22px; }
		@include tablet {
			margin-bottom: 68px; }
		@include md {
			margin-bottom: 50px; }
		@include sm {
			margin-bottom: 37px; } }
	&__tip {
		font-size: 18px;
		text-align: center;
		margin: 72px 0 0;

		a {
			color: $themeColor2; }

		@include md {
			margin: 52px 0 0; }
		@include sm {
			margin: 32px 0 0; } }
	&__suptitle {
		text-align: center;
		font-size: 33px;
		max-width: 540px;
		letter-spacing: 0.4px;
		line-height: 1.4;
		margin: 0 auto 44px;
		@include r(1480) {
			font-size: 24px;
			max-width: 100%;
			margin-bottom: 14px; }
		@include tablet {
			font-size: 29px;
			max-width: 530px;
			margin-bottom: 46px; }
		@include md {
			font-size: 27px;
			margin-bottom: 41px;
			max-width: 490px; }
		@include sm {
			font-size: 24px;
			margin-bottom: 25px;
			max-width: 100%; } }
	&__title {
		font-size: 92px;
		color: #fff;
		font-weight: 700;
		line-height: 1.06;
		text-align: center;
		margin: 5px auto 56px;
		@include black;
		@include r(1360) {
			font-size: 6.7vw; }
		@include tablet {
			margin-bottom: 51px; }
		@include md {
			margin-bottom: 40px; }
		@include sm {
			font-size: 6vw;
			letter-spacing: 0;
			margin: 0 0 27px; }
		@include xs {
			font-size: 32px;
			letter-spacing: 3.2px; } }


	&__text {
		font-size: 18px;
		max-width: 1040px;
		padding: 0 20px;
		line-height: 1.9;
		letter-spacing: -.001em;
		text-align: center;

		p {
			margin: 15px 0 0;

			@include first {
				margin: 0; } }
		@include md {
			//margin: 0 -13px
			//letter-spacing: 0
			font-size: 17px;
			max-width: 100%;
			line-height: 1.5; }
		@include sm {
			font-size: 15px;
			padding: 0;
			margin: 0;
			line-height: 1.42; }
		@include xs {
			a {
				display: block; } } }
	&__items {
		display: flex;
		margin: 72px 0 62px;
		justify-content: center;
		flex-wrap: wrap;
		justify-content: center;
		@include tablet {
			margin: 64px 0; }
		@include sm {
			flex-direction: column;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between; } }
	&__item {
		display: flex;
		justify-content: center;
		margin: 0 5px 10px;
		@include sm {
			margin: 10px 0 0;
			max-width: 300px;
			justify-content: center;
			align-items: flex-start;
			width: 100%; } }

	&__techs-title {
		@include bold;
		font-size: 26px;
		margin: 82px auto 52px;
		text-align: center;

		@include sm {
			font-size: 22px;
			margin: 62px auto 32px; }

		@include xs {
			font-size: 18px;
			margin: 52px auto 22px; } } }

.e-item {
	flex: 1 1 auto;
	text-decoration: none;
	text-align: center;
	color: $white;
	display: flex;
	flex-direction: column;
	background: #131513;
	border: 1px solid #2c302d;
	@include hover {
		.e-item__footer {
			text-decoration: none; } }
	&__header {
		color: #525252;
		text-transform: uppercase;
		font-size: 14px;
		background-color: #131513;
		padding: 32px 10px 6px;
		position: relative;
		border-bottom: 4px dotted #131614; }

	&__content {
		background-color: #131513;
		padding: 24px 0 42px;
		border-radius: 0 0 8px 8px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		@include tablet {
			padding: 23px 0 31px; } }
	&__date {
		@include medium;
		font-size: 60px;
		padding: 0 45px;
		line-height: 1;
		margin-bottom: 36px;
		@include tablet {
			padding: 0 25px; }
		@include sm {
			font-size: 46px;
			margin-bottom: 26px; }
		@include xs {
			margin-bottom: 20px; } }
	&__footer {
		font-size: 18px;

		a.e-item & {
			text-decoration: underline; }

		a {
			color: $white; }

		br {
			display: none; }
		@include tablet {
			font-size: 16px; } } }

a.e-item:hover {
	.e-item__footer {
		text-decoration: none; } }

