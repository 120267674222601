.coc {
	padding: 74px 0 80px;
	&__description {
		text-align: center;
		font-size: 20px;
		line-height: 1.6;
		max-width: 970px;
		margin: 40px auto 0;
		@include md {
			font-size: 18px; }
		@include xs {
			font-size: 16px; }
		a {
			color: inherit;
			text-decoration: none;
			&:hover {
				text-decoration: underline; } }
		p {
			&:not(:last-child) {
				margin-bottom: 40px; } } }
	&__content {
		h2 {
			font-size: 48px;
			line-height: 1.2;
			margin: 40px 0;
			text-align: center;
			@include md {
				font-size: 38px;
				margin: 32px 0; } }
		p {
			color: $white;
			font-size: 16px;
			line-height: 1.64;
			@include light; }
		a {
			color: $themeColor2;
			text-decoration: none;
			&:hover {
				text-decoration: underline; } } } }

