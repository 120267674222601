$font-size: 16px;

/*----- COLORS -----*/

$mainBg: #0032b0;
$mainBg2: #fafbfc;
$black: #000;
$white: #fff;
$gray: #e5e5e5;
$text: #293249;
$text2: #444f6d;
$themeColor: #0032b0;
$themeColorD: #001854;
$themeColor2: #fcd725;
$accent: #b1ec00;



// end COLORS
