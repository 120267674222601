.media-box {
	padding: 100px 0;
	@include laptop {
		padding: 60px 0; }
	@include tablet {
		padding: 40px 0; }
	@include xs {
		padding: 10px 0;
		margin-bottom: 44px; }
	//+md
 //     display: none
	&__grid {
		padding-top: 39.7%;
		width: 100%;
		position: relative;
		@include md {
			padding-top: 52.25%; }
		@include sm {
			padding-top: 264.75%; }
		& + .section__text {
			margin-top: 72px;
			@include tablet {
				margin-top: 30px; } } }

	&__text {
		font-size: 18px;
		@include xs {
			padding: 0 20px; } } }
.m-grid {
	@include coverdiv;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	grid-template-areas: "m-grid__1 m-grid__1 m-grid__2 m-grid__3 m-grid__4" "m-grid__1 m-grid__1 m-grid__5 m-grid__5 m-grid__4" "m-grid__6 m-grid__6 m-grid__5 m-grid__5 m-grid__7";
	@include sm {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		grid-template-areas: "m-grid__1 m-grid__1" "m-grid__2 m-grid__3" "m-grid__6 m-grid__6" "m-grid__7 m-grid__7"; }
	&__item {
		overflow: hidden;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			display: block;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 62px;
			height: 62px;
			background: url(../img/m-box_fb.svg) center center no-repeat;
			opacity: 0;
			pointer-events: none;
			transition: opacity .3s ease;
			display: none; }
		@include hover {
			&:after {
				opacity: 1; }
			img {
				transform: scale(1.05); } } }
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		transition: all ease 0.2s; } }

.m-grid__2,
.m-grid__3,
.m-grid__4,
.m-grid__6,
.m-grid__7 {
	&:after {
		display: block; } }



.m-grid__1 {
	-ms-grid-column: 1;
	-ms-grid-column-span: 2;
	-ms-grid-row: 1;
	-ms-grid-row-span: 2;
	grid-area: m-grid__1;
	@include sm {
		-ms-grid-column: 1;
		-ms-grid-column-span: 2;
		-ms-grid-row: 1;
		-ms-grid-row-span: 2; } }


.m-grid__2 {
	-ms-grid-column: 3;
	-ms-grid-column-span: 1;
	-ms-grid-row: 1;
	grid-area: m-grid__2; }

.m-grid__3 {
	-ms-grid-column: 4;
	-ms-grid-column-span: 1;
	-ms-grid-row: 1;
	grid-area: m-grid__3; }

.m-grid__4 {
	-ms-grid-column: 5;
	-ms-grid-column-span: 4;
	-ms-grid-row: 1;
	-ms-grid-row-span: 2;
	grid-area: m-grid__4;
	@include sm {
		display: none; } }

.m-grid__5 {
	-ms-grid-column: 3;
	-ms-grid-column-span: 2;
	-ms-grid-row: 2;
	-ms-grid-row-span: 2;
	grid-area: m-grid__5;
	@include sm {
		display: none; } }

.m-grid__6 {
	-ms-grid-column: 1;
	-ms-grid-column-span: 2;
	-ms-grid-row: 3;
	grid-area: m-grid__6; }


.m-grid__7 {
	-ms-grid-column: 5;
	-ms-grid-column-span: 1;
	-ms-grid-row: 3;
	grid-area: m-grid__7; }
