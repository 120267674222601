.random-party {
	padding: 42px 0 42px;
	background: url(../img/screens-done-op.svg) center center no-repeat;
	@include laptop {
		padding: 40px 0 40px; }
	@include md {
		padding: 50px 0 50px; }
	@include xs;
	.section__title {
		letter-spacing: 1.3px;
		margin-bottom: 55px;
		@include tablet {
			margin-bottom: 37px; } }
	.container {
		@include xs {
			padding: 0 16px; } }
	&__inner {
		padding: 82px 20px 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include tablet {
			padding: 40px 20px; }
		@include md {
			padding: 0;
			border: none; } }

	&__text {
		line-height: 1.6;
		letter-spacing: 0.003em;
		max-width: 540px;
		text-align: center;
		font-size: 18px;
		margin-bottom: 42px;
		@include tablet {
			font-size: 16px;
			margin-bottom: 38px; } }

	&__btn {
		max-width: 368px;
		width: 100%;
		margin: 0 auto; } }
