.s-video-rooms {
	padding: 110px 0;
	@include laptop {
		padding: 80px 0; }
	@include tablet {
		padding: 72px 0; }
	@include md {
		padding: 103px 0; }
	@include sm {
		padding: 40px 0; } }

.video-rooms {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 62px 0 30px;
	padding: 30px 0;

	@include md {
		margin: 30px 0 20px; }
	@include sm {
		margin: 10px 0 10px; }

	&__item {
		position: relative;
		margin: 10px 20px;
		padding: 32px 32px 22px;
		background: transparent;
		border: 4px solid #0b50ff;
		border-radius: 7px;
		width: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: all ease 0.1s;

		@include md {
			margin: 10px 10px; }

		@include sm {
			margin: 10px 0; }

		&.hoverable {

			&:hover {
				background: rgba(0, 0, 0, 0.2);

				.video-rooms__btn {
					border-color: $themeColor2;

					&:hover {
						background: $themeColor2;
						color: $text2; } } } } }


	&__link {
		position: absolute;
		display: block;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0; }

	&__img-wrap {
		display: flex;
		width: 100%;
		height: 168px; }

	&__img {
		display: block;
		margin:  auto;
		max-height: 100%; }

	&__users {
		margin: 34px auto 26px; }

	&__user {
		display: flex;
		align-items: center;
		flex: 1 1 auto;
		margin: 20px auto 0;

		&:first-child {
			margin: 0; } }

	&__avatar {
		width: 48px;
		height: 48px;
		margin: 0 18px 0 0;
		border-radius: 50%; }

	&__name {
		font-size: 16px;
		line-height: 1.28; }

	&__btn {
		@include medium;
		margin: auto 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 46px;
		padding: 0 20px;
		font-size: 10px;
		color: $themeColor2;
		text-transform: uppercase;
		text-decoration: none;
		border: 2px solid transparent;
		text-align: center;
		transition: all ease 0.1s;
		cursor: pointer;
		transition: all ease .1s;
		border-radius: 5px; } }
