.sponsors {
	padding: 100px 0;
	background: $white;
	@include md {
		padding: 40px 0; }
	.container {
		max-width: 1110px; }
	.section__title {
		color: $text;
		@include sm {
			margin-bottom: 28px; } }
	&__description {
		max-width: 960px;
		margin: 0 auto 62px;
		@include r(680) {
			margin-bottom: 42px; }
		//+r(480)
		//argin-bottom: 30px
		p {
			font-size: 20px;
			line-height: 1.6;
			text-align: center;
			@include r(1480) {
				font-size: 18px; }
			@include sm {
				line-height: 1.8; }
			@include r(680) {
				font-size: 16px; }
			&:not(:last-child) {
				margin-bottom: 18px;
				@include sm {
					margin-bottom: 5px; } }
			a {
				color: inherit; } } } }

.sponsors-block {
	&__item {
		text-align: center; }
	& + & {
		margin-top: 50px; }
	&_xl {
		.sponsors-block__item {
			max-width: 50%;
			width: 33.33%;
			padding: 25px 30px;
			@include tablet {
				padding: 20px;
				img {
					max-width: 100%; } }
			@include md {
				width: 50%; }
			@include sm {
				width: 75%;
				max-width: 100%;
				padding: 20px 10px; } } }
	&_lg {
		//.sponsors-block__list
		//argin: 0 -50px
		//include tablet
		//margin: 0 -20px
		//include xs
		//margin: 0 -10px
		.sponsors-block__item {
			padding: 25px 30px;
			max-width: 50%;
			width: 33.33%;
			img {
				max-width: 100%; }
			@include tablet {
				padding: 20px; }
			@include md {
				width: 50%; }

			@include xs {
				padding: 7px; }
 } }			//max-width: 217px
	&_md {
		.sponsors-block__list {
			margin: 0 -50px;
			@include tablet {
				margin: 0 -20px; }
			@include xs {
				margin: 0 -10px; } }
		.sponsors-block__item {
			padding: 25px 30px;
			width: 33.33%;
			img {
				max-width: 100%; }
			@include tablet {
				padding: 20px; }
			@include xs {
				padding: 5px; }
			//max-width: 193px
 } }			//width: 100%
	&_sm {
		.sponsors-block__list {
			margin: 0 -25px; }
		.sponsors-block__item {
			padding: 20px 25px;
			width: 25%;
			img {
				max-width: 100%; }
			@include tablet {
				padding: 20px; }

			@include md {
				width: 33.33%; }
			//+md
			//idth: 50%
			//mg
 } }			//max-width: 100%

	&_xs {
		.sponsors-block__list {
			margin: 0 -15px; }
		.sponsors-block__item {
			width: 20%;
			a {
				padding: 20px 25px; }
			img {
				max-width: 100%; }
			@include tablet {
				a {
					padding: 20px; } }

			@include md {
				width: 25%; }

			@include xs {
				width: 33.3%;
				a {
					padding: 15px 10px; } } } }

	&_auto {
		.sponsors-block__list {
			justify-content: space-between;
			margin: 0 -15px; }
		.sponsors-block__item {
			width: auto;
			a {
				padding: 20px 25px; }
			img {
				max-width: 100%; }
			&:last-child {
				margin: 0 auto 0 0; }
			@include tablet {
				a {
					padding: 20px; } }

			@include sm {
				width: 25%;
				a {
					padding: 15px 10px; } }

			@include xs {
				width: 33.3%;
				a {
					padding: 15px 10px; } } } }
	&__list {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap; }
	&__item {
		@include tablet {
			display: flex;
			justify-content: center; }
		@include sm {
			max-width: 230px;
			img {
				max-width: 100%; } }
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 0.3s;

			&:hover {
				opacity: 0.7; }

			img {
				transition: all ease 0.2s;
				transform: translateY(0);
				opacity: 1;
				height: auto; } } }

	&__title {
		text-align: center;
		font-size: 22px;
		@include regular;
		color: $text2;
		margin-bottom: 20px;
		@include sm {
			color: #aaaaaa;
			font-size: 16px;
			margin-bottom: 32px; } } }
