.s-sponsors-offers {
	padding: 120px 0 120px;
	@include md {
		padding: 80px 0 80px; }
	@include sm {
		padding: 60px 0 60px; } }

.sponsors-offers {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 68px auto 0;
	max-width: 1465px;

	@include sm {
		margin: 0 -20px 0 -20px;
		padding: 0 10px; }

	@include xs {
		overflow-x: auto;
		flex-wrap: nowrap; }

	&__item {
		width: calc(25% - 20px);
		box-sizing: border-box;
		border: 4px solid #2e2e2c;
		background: #191917;
		padding: 0 30px 60px;
		margin: 0 10px 20px;
		display: flex;
		flex-direction: column;

		@include md {
			width: calc(33.3% - 20px);
			margin: 0 10px 20px;
			padding: 0 20px 30px; }

		@include sm {
			width: calc(33.3% - 10px);
			margin: 0 5px 10px;
			padding: 0 20px 20px; }

		@include xs {
			min-width: 250px;
			width: calc(50% - 10px); } }

	&__img-wrap {
		height: 160px;
		display: flex;
		padding: 0 0; }

	&__img {
		max-width: 100%;
		height: auto;
		max-height: 100px;
		margin: auto;
		cursor: pointer; }

	&__learn-more {
		text-align: center;
		button {
			appearance: none;
			border: none;
			background: none;
			text-decoration: underline;
			text-transform: uppercase;
			@include bold;
			color: $themeColor;
			&:hover {
				text-decoration: none; } } }

	&__desc {
		margin-top: 15px;
		border-top: 2px solid #2e2e2c;
		font-size: 18px;
		padding: 34px 0 43px;
		line-height: 1.4;
		@include tablet {
			font-size: 18px; } }

	&__btn {
		min-height: 60px;
		padding: 10px 10px;
		font-size: 14px;
		color: $themeColor;
		margin: 0 20px;
		margin: auto 20px 0;
		@include tablet {
			padding: 10px 10px; }
		@include md {
			margin: auto 0 0; } } }

