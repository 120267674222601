/* Fonts
-----------------------------------------------------------------------------*/

@font-face {
	font-family: "GothamPro";
	src:url(../fonts/GothamPro-Light.woff2) format("woff2"),
		url(../fonts/GothamPro-Light.woff) format("woff");
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "GothamPro";
	src:url(../fonts/GothamPro.woff2) format("woff2"),
		url(../fonts/GothamPro.woff) format("woff");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "GothamPro";
	src:url(../fonts/GothamPro-Medium.woff2) format("woff2"),
		url(../fonts/GothamPro-Medium.woff) format("woff");
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: "GothamPro";
	src:url(../fonts/GothamPro-Bold.woff2) format("woff2"),
		url(../fonts/GothamPro-Bold.woff) format("woff");
	font-style: normal;
	font-weight: 700;
}