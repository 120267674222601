.info-block {
	position: relative;
	padding: 0;
	min-height: 38vw;
	display: flex;

	@include xs {
		min-height: 100vw; }

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: url(../img/quake.gif) center center no-repeat;
		background-size: cover;
		filter: grayscale(100%);
		z-index: -1; }


	.section__title {
		letter-spacing: 1.3px;
		margin-bottom: 55px;
		@include tablet {
			margin-bottom: 37px; } }
	.container {
		@include xs {
			padding: 0 16px; } }
	&__inner {
		padding: 20px;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include tablet {
			padding: 40px 20px; }
		@include md {
			padding: 60px 20px;
			border: none; } }

	&__text {
		line-height: 1.6;
		letter-spacing: 0.003em;
		max-width: 600px;
		text-align: center;
		font-size: 18px;
		margin-bottom: 42px;
		@include tablet {
			font-size: 16px;
			margin-bottom: 38px; } }

	&__btn {
		max-width: 368px;
		width: 100%;
		margin: 0 auto; } }
